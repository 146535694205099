$primary-color: #6167e6;
$hover-color: #8f93cef8;
$font-family: "Arial", sans-serif;

.eventbooking {
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;

    th,
    td {
      padding: 12px;
    }

    th {
      background-color: $primary-color;
      color: white;
    }

    td {
      button {
        background-color: $primary-color;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }
}
