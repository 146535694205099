.customer {
  .closer-avatar {
    width: 20px;
  }
  .historie-entry {
    display: flex;
    gap: 10px;
  }

  .text-gray {
    color: #a3a3a3;
  }

  .timeline-icon {
    font-size: 28px;

    &.crossed {
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #e74d3c;
        top: 50%;
        left: 0;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
}
