header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
  position: relative;

  h1 {
    font-size: 2rem;
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .logo {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 15px 0px 0px 0px;
    padding: 0;
  }

  li {
    margin: 0 1rem;
  }

  .profile-area {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 4rem;
    top: 5px;

    width: 100px;

    img {
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
}
